<!--
 Copyright 2022 Semantic STEP Technology GmbH, Germany & DCT Co., Ltd. Tianjin, China
-->

<template>
  <div>
    <span @click="scroll('overview')" class="link">Overview</span>
    |
    <span @click="scroll('nodes')" class="link">Nodes</span
    ><span v-if="!dsServiceSessions.has($route.params.dsID)">
      |
      <span @click="scroll('commits')" class="link">Commits</span></span
    >
  </div>
</template>

<script>
import { useDatasetEditSessions } from "../stores/dsEditSessions";
export default {
  name: "SSTDatasetNavigation",
  setup() {
    const dsEditSessions = useDatasetEditSessions();
    return { dsServiceSessions: dsEditSessions.serviceSessions };
  },
  methods: {
    scroll(id) {
      var element = document.getElementById(id);
      var top = element.offsetTop;
      window.scrollTo(0, top - 50);
    },
  },
};
</script>
